<template>
  <div class="subscription">
    <Header />
    <b-container class="subscription-container">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <div class="row justify-content-center mx-auto my-auto">
            <div class="text-center">
              <img src="@/assets/img/loading/loading.gif" width="100px" />
            </div>
          </div>
        </template>
        <b-row class="justify-content-md-start back-nav">
          <div class="col-lg-12 col-md-6">
            <b-link :to="'/subscription-product-detail/' +
              product.fund_code +
              '/' +
              product.id +
              '/' +
              product.fund_name
              "><b-icon class="mx-2" icon="chevron-left"></b-icon><span>Kembali</span></b-link>
          </div>
        </b-row>

        <b-row class="justify-content-md-center p-4">
          <b-card class="subscription-form">
            <b-form>
              <div class="fund-name">
                <b-row>
                  <div class="col-3 col-lg-1">
                    <!-- <div v-if="product.image == null">
                      <img
                        :src="
                          'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                          product.fund_name
                        "
                      />
                    </div>
                    <div v-else>
                      <img
                        :src="`${imageCore}/images/products/${product.image}`"
                        alt="Fund Image"
                      />
                    </div> -->
                    <img :src="product.investment_manager.logo" size="2rem" alt="Fund Image" />
                  </div>
                  <div class="col-9">
                    <h4>
                      {{ product.fund_name }}
                    </h4>
                    <p v-text="product.investment_manager.im_name"></p>
                  </div>
                </b-row>
              </div>
              <div class="subscription-input-nominal mt-4">
                <div class="row">
                  <div class="col-lg-6 col-md-6 text-center text-lg-left">
                    <h4>Tentukan modal investasi Anda</h4>
                    <p>Minimum {{ product.fund_ccy }}{{ formatPrice(product.min_buy) }}</p>
                  </div>
                  <div class="col-lg-6 col-md-6 text-center text-lg-right">
                    <h4>Available Cash Balance</h4>
                    <p>{{ product.fund_ccy }}{{ formatPrice(balancePosition) }}</p>
                  </div>
                </div>
                <template>
                  <div>
                    <vs-input class="mb-2 mr-sm-2 mb-sm-0" min="product.min_buy" id="minimum-buy" type="text"
                      v-model="invest_nominal" @change="inputSubscription" />
                  </div>
                </template>

                <template v-if="product.fund_ccy == 'USD'">
                  <div class="mb-3 mt-4">
                    <div class="invest-nominal">
                      <b-row class="mb-3">
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button"
                            v-on:click="setInvestNominal(10000)">
                            {{ product.fund_ccy }}10.000
                          </button>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button" @click="setInvestNominal(25000)">
                            {{ product.fund_ccy }}25.000
                          </button>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button" @click="setInvestNominal(50000)">
                            {{ product.fund_ccy }}50.000
                          </button>
                        </div>
                      </b-row>

                      <b-row>
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button"
                            v-on:click="setInvestNominal(100000)">
                            {{ product.fund_ccy }}100.000
                          </button>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button" @click="setInvestNominal(150000)">
                            {{ product.fund_ccy }}150.000
                          </button>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button" @click="setInvestNominal(200000)">
                            {{ product.fund_ccy }}200.000
                          </button>
                        </div>
                      </b-row>
                    </div>
                  </div>
                </template>

                <template v-else>
                  <div class="mb-3 mt-4">
                    <div class="invest-nominal">
                      <b-row class="mb-3">
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button"
                            v-on:click="setInvestNominal(10000)">
                            {{ product.fund_ccy }}10rb
                          </button>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button"
                            v-on:click="setInvestNominal(50000)">
                            {{ product.fund_ccy }}50rb
                          </button>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button"
                            v-on:click="setInvestNominal(100000)">
                            {{ product.fund_ccy }}100.000
                          </button>
                        </div>
                      </b-row>
                      <b-row class="mb-3">

                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button" @click="setInvestNominal(250000)">
                            {{ product.fund_ccy }}250.000
                          </button>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button" @click="setInvestNominal(500000)">
                            {{ product.fund_ccy }}500.000
                          </button>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button"
                            v-on:click="setInvestNominal(1000000)">
                            {{ product.fund_ccy }}1.000.000
                          </button>
                        </div>
                      </b-row>

                      <b-row>
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button" @click="setInvestNominal(2500000)">
                            {{ product.fund_ccy }}2.500.000
                          </button>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button" @click="setInvestNominal(5000000)">
                            {{ product.fund_ccy }}5.000.000
                          </button>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-2">
                          <button class="btn-invest-nominal py-2 px-2" type="button"
                            @click="setInvestNominal(10000000)">
                            {{ product.fund_ccy }}10.000.000
                          </button>
                        </div>
                      </b-row>
                    </div>
                  </div>
                </template>
                <b-button block :disabled="disabled" class="mt-2 btn-tertiary py-2"
                  @click="next_buy()">Lanjut</b-button>
              </div>
            </b-form>
          </b-card>
        </b-row>
      </b-skeleton-wrapper>
    </b-container>
    <div>
      <b-modal id="modal-failed-register" v-model="nominalIsEmpty" size="md" class="modal-failed-register" centered
        hide-footer>
        <div class="d-block text-center">
          <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
          <h1 class="header-modal p-4 my-2 text-dark">
            Isi nominal pembelian terlebih dahulu
          </h1>
        </div>
      </b-modal>
      <b-modal id="modal-failed-register" v-model="nominalIsLower" size="md" class="modal-failed-register" centered
        hide-footer>
        <div class="d-block text-center">
          <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
          <h1 class="header-modal p-4 my-2 text-dark">
            Nominal pembelian tidak memenuhi minimal pembelian produk
          </h1>
        </div>
      </b-modal>
      <b-modal id="modal-failed-register" v-model="nominalIsMinus" size="md" class="modal-failed-register" centered
        hide-footer>
        <div class="d-block text-center">
          <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
          <h1 class="header-modal p-4 my-2 text-dark">
            Available Cash Balance tidak cukup
          </h1>
        </div>
      </b-modal>
      <b-modal id="modal-failed-register" v-model="nominalIsGreater" size="md" class="modal-failed-register" centered
        hide-footer>
        <div class="d-block text-center">
          <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
          <h1 class="header-modal p-4 my-2 text-dark">
            Nominal pembelian melebihi Available Cash Balance
          </h1>
        </div>
      </b-modal>
      <b-modal id="modal-failed-register" v-model="clientSuspend" size="md" class="modal-failed-register" centered
        hide-footer>
        <div class="d-block text-center">
          <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
          <h1 class="header-modal p-4 my-2 text-dark">
            Akun Anda telah ditangguhkan (suspend)
          </h1>
        </div>
      </b-modal>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import { mapGetters } from "vuex";

import instance from "@/utils/api";
import { encryptData } from "@/utils";

export default {
  name: "Subscription",
  metaInfo: {
    title: "Pembelian | CGS iTrade Fund",
  },
  data() {
    return {
      product: {
        investment_manager: {
          im_name: "",
        },
      },
      clientSuspend: false,
      balancePosition: [],
      fund_code: "",
      invest_nominal: "",
      disabled: false,
      nominalIsEmpty: false,
      nominalIsLower: false,
      nominalIsGreater: false,
      nominalIsMinus: false,
      loading: false,
      loadingTime: 0,
      maxLoadingTime: 2,
      clientId: localStorage.checkUserId,
      imageCore: process.env.VUE_APP_IMAGE_URL,
    };
  },
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      app_url: "app_url",
    }),
  },
  created() {
    this.fund_code = this.$route.params.fund_code;
    this.fetchFund();
    this.fetchBalancePositions();
    localStorage.removeItem("invoiceUrl");
    this.$_loadingTimeInterval = null;
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    },
    invest_nominal(value) {
      this.invest_nominal = this.formatRupiah(value)
    }
  },
  methods: {
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    // clickInput() {
    //   if (this.invest_nominal == 0) {
    //     this.invest_nominal = null;
    //   } else {
    //     this.invest_nominal = localStorage.invest_nominal;
    //   }
    // },
    inputSubscription: function (e) {
      if (parseInt(e.target.value.replace(/\D/g, '')) < parseInt(this.product.min_buy)) {
        this.nominalIsLower = true;
      } else if (parseInt(this.invest_nominal.replace(/\D/g, '')) > (parseInt(this.balancePosition) - 100000)) {
        this.nominalIsGreater = true;
      } else if (0 >= (parseInt(this.balancePosition) - 100000)) {
        this.nominalIsMinus = true
      } else {
        this.invest_nominal = this.formatRupiah(e.target.value)
        localStorage.invest_nominal = this.formatRupiah(e.target.value)
      }
    },
    formatRupiah(value) {
      return value.replace(/[^0-9]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    setInvestNominal: function (nominal) {
      localStorage.invest_nominal = this.formatRupiah(nominal + '');
      this.invest_nominal = this.formatRupiah(nominal + '');
      this.disabled = false;
    },

    async next_buy() {
      this.loading = true;

      let s21client = await instance({
        method: "GET",
        url: `${this.app_url}s21client?clientId=${encryptData(
          this.clientId
        )}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (s21client.data.data.ClientStatus != 1) {
        this.clientSuspend = true;
        this.loading = false;
        //  setTimeout(() => this.$router.push('/portfolio'), 2000);
        return;
      }

      if (
        typeof this.invest_nominal == "undefined" ||
        this.invest_nominal == 0 ||
        localStorage.invest_nominal == 0
      ) {
        this.nominalIsEmpty = true;
      } else if (
        parseInt(this.invest_nominal.replace(/\D/g, '')) < parseInt(this.product.min_buy)
      ) {
        this.nominalIsLower = true;
      } else if (parseInt(this.invest_nominal.replace(/\D/g, '')) > (parseInt(this.balancePosition) - 100000)) {
        this.nominalIsGreater = true;
      } else if (0 >= (parseInt(this.balancePosition) - 100000)) {
        this.nominalIsMinus = true
      } else {
        this.$router.push("/subscription-review/" + this.product.fund_code);
      }
    },

    async fetchFund() {
      this.loading = true;

      let response = await axios({
        method: "GET",
        url: `${this.app_url}product/${this.fund_code}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });

      if (!response.data.data) {
        return this.$router.push("/NOT-FOUND");
      }

      this.product = response.data.data;
      this.loadingTime = 0;
    },
    async fetchBalancePositions() {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}balance/position?client_id=${this.clientId}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      });
      this.balancePosition = response.data.data.amount;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription {
  background: #f3f4f6;
}

.subscription-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.subscription-form {
  padding: 20px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

.vs-con-input-label {
  width: 100%;
}

.back-nav {
  padding: 0px 144px 0px 128px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }
}

.subscription-input-nominal {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #1f2937;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #9ca3af;
  }
}

.btn-invest-nominal {
  font-family: $inter;
  background: white;
  border-radius: 2px;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #374151;
  width: 230px;
}

.btn-invest-nominal:hover {
  background: $tertiaryColor;
  color: white;
}

.btn-invest-nominal:focus {
  box-shadow: none !important;
}

.btn-invest-nominal:active {
  background: $tertiaryColor !important;
  outline: none !important;
  color: white !important;
}

.btn-tertiary:disabled {
  cursor: no-drop !important;
}

.header-modal {
  font-size: 32px;
}

@media only screen and (max-width: 768px) {
  .fund-name {
    h4 {
      font-size: 16px !important;
    }
  }

  .back-nav {
    padding: 0px;
  }

  .btn-invest-nominal {
    width: 100%;
  }

  .header-modal {
    font-size: 16px !important;
  }
}
</style>
